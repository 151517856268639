import React from "react";
import "./index.css";
import styled from "styled-components";
import { Link } from 'gatsby';
import Logo from "../Images/Logo.png";
import IconFacebook from "../Images/IconFacebook.png";
import IconInsta from "../Images/IconInsta.png";
import { Main, Header, HeaderLogo, HeaderNav, Footer, Content, FooterContentBox, FooterContent, FooterSlogan, FooterText} from "../styles/Global";



const NavLink = styled(Link)`
  display: flex; 
  text-decoration: none;
  font-family: ‘Roboto’, sans-serif;
  height: 94px;
  align-items: center; 
  color: white;
  @media (max-width: 900px) {
    height: 44px;
    font-size: 12px;
  }
`;

const FooterNav = styled(Link)`
  font-size: 20px;
  font-family: ‘Roboto’, sans-serif;
  font-weight: normal;
  color: #ffff;
  margin: 0 0 10px 0;
  text-decoration: none;
  @media (max-width: 1050px) {
    font-size: 15px;
  }
  @media (max-width: 900px) {
    font-size: 8px;
  }
`;

const FooterIconBox = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  width: auto;
  justify-content: flex-end;
`;

const FooterIconA = styled.div`
  height: auto;
  width: 35%;
  background-image: url(${IconInsta});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  margin: 0 0 0 0;
  @media (max-width: 900px) {
    width: 15%;
  }
`;

const FooterIconB = styled.div`
  height: auto;
  width: 35%;
  background-image: url(${IconFacebook});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  margin: 0 0 0 0;
  @media (max-width: 900px) {
    width: 15%;
    margin-left: 10px;
  }
`;


export default function DefaultLayout({children, ...props}) {
  return (
    <Main>
        <Header>
            <HeaderLogo>
            </HeaderLogo>
            <HeaderNav>
                <NavLink
                    to={`/`}
                    activeStyle={{ borderBottom: "3px solid white", borderTop: "3px solid transparent"}}
                >
                    Home
                </NavLink>
                <NavLink
                    to={`/contact`}
                    activeStyle={{ borderBottom: "3px solid white", borderTop: "3px solid transparent"}}
                >
                    Contact
                </NavLink>
                <NavLink
                    to={`/#about-us`}
                    activeStyle={{ borderBottom: "3px solid white", borderTop: "3px solid transparent"}}
                >
                    About Us
                </NavLink>
                <NavLink
                    to={`/services`}
                    activeStyle={{ borderBottom: "3px solid white", borderTop: "3px solid transparent"}}
                >
                    Services
                </NavLink>
            </HeaderNav>
        </Header>
        <Content>
            {children}
        </Content>
        <Footer>
            <FooterContentBox spaced>
                <FooterContent>
                    <FooterSlogan>
                        Company
                    </FooterSlogan>
                    <FooterNav to={`/#about-us`}>
                        About Us
                    </FooterNav>
                    <FooterNav to={`/services`}>
                        Our Services
                    </FooterNav>
                    <FooterNav to={`/#why-important`}>
                        Why Important?
                    </FooterNav>
                    <FooterNav to={`/contact`}>
                        Contact Us
                    </FooterNav>
                </FooterContent>
                <FooterContent>
                        <FooterSlogan>
                        Legal
                        </FooterSlogan>
                        <FooterText>
                            Privacy Policy
                        </FooterText>
                        <FooterText>
                            Cookies Notice
                        </FooterText>
                </FooterContent>
                <FooterContent>
                        <FooterSlogan>
                        Spokane Sweeps
                        </FooterSlogan>
                        <FooterText>
                        Spokane Sweeps is a lot maintenance company in the Spokane area, offering detailed lot sweeping and exterior porter services.
                    </FooterText>
                </FooterContent>
            </FooterContentBox>
            <FooterContentBox reversed>
                <FooterContent>
                    <FooterSlogan reversed>
                        Follow Us
                    </FooterSlogan>
                    <FooterIconBox>
                        <FooterIconA>
                        </FooterIconA>
                        <FooterIconB>
                        </FooterIconB>
                    </FooterIconBox>
                </FooterContent>
            </FooterContentBox>
        </Footer>
    </Main>
  );
}
