import React from "react";
import styled from 'styled-components';
import { Link } from "gatsby";
import Logo from "../Images/Logo.png"
import Map from "../Images/MapBackground.png";
import BlueBackgroundA from "../Images/BlueBackgroundA.png";
import BlueBackgroundB from "../Images/BlueBackgroundB.png";
import HeroImg from "../Images/Hero.png"
import LotB from "../Images/LotB.png";
import IconPhone from "../Images/IconPhone.png";
import IconAt from "../Images/IconAt.png";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  `;

export const Hero = styled(({ reversed, ImgReversed, ...props }) => (<div {...props} />))`
  flex-direction: ${({reversed}) => reversed ? `column` : `row`};
  background-image: ${({ImgReversed}) => ImgReversed ? `url(${LotB})` : `url(${HeroImg})`};
  background: ${({ImgReversed}) => ImgReversed ? `` : `linear-gradient(to left, transparent, #fff), url(${HeroImg})`};
  justify-content: ${({ImgReversed}) => ImgReversed ? `flex-end` : `flex-start`};
  background-position: ${({ImgReversed}) => ImgReversed ? `left bottom` : `center`};
  background-size: ${({ImgReversed}) => ImgReversed ? `500px` : `100%`};
  background-repeat: no-repeat;
  display: flex;
  margin: 0 0 0 0;
  background-color: #ffff;
  width: 100%;
  height: 1000px;
  @media (max-width: 1050px) {
    height: 600px;
    background-size: ${({ImgReversed}) => ImgReversed ? `350px` : `100%`};
  }
  @media (max-width: 900px) {
    height: 500px;
    background-size: ${({ImgReversed}) => ImgReversed ? `250px` : `100%`};
  }
  @media (max-width: 425px) {
    height: 450px;
    justify-content: center;
    justify-content: ${({reversed}) => reversed ? `space-evenly` : `space-evenly`};
    align-items: center;
    background-image: ${({ImgReversed}) => ImgReversed ? `none` : `none`};
  }
`;

export const HeroServices = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 0;
  background-color: #ffff;
  width: 100%;
  height: 1000px;
  @media (max-width: 900px) {
    height: auto;
  }
  @media (max-width: 425px) {
    height: auto;
  }
`;

export const HeroText = styled(({ reversed, ...props }) => (<div {...props} />))`
  align-self: ${({reversed}) => reversed ? `flex-start` : `center`};
  justify-content: ${({reversed}) => reversed ? `space-around` : `center`};
  margin: ${({reversed}) => reversed ? `100px 80px 0 0` : `0 140px 0 80px`};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 60%; 
  height: auto;
  @media (max-width: 1050px) {
    margin: ${({reversed}) => reversed ? `10px 20px 0 40px` : `0 0 0 60px`};
  }
  @media (max-width: 900px) {
    margin: ${({reversed}) => reversed ? `10px 20px 0 40px` : `0 0 0 40px`};
  }
  @media (max-width: 425px) {
    width: 100%;
    margin: 0 0 0 0;
    align-items: center;
    justify-content: space-evenly;
    align-self: center;
  }
`;

export const HeroTextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0 0 0;
  @media (max-width: 900px) {
    margin: 25px 0 0 0;
  }
  @media (max-width: 425px) {
    width: 100%;
    margin: 0 0 0 0;
    align-items: center;
  }
`;

export const HeroP = styled(({ bold, ...props }) => (<h3 {...props} />))`
  font-weight: ${({bold}) => bold ? `bold` : `normal`};
  margin: ${({bold}) => bold ? `2px 0 2px; 0` : `25px 0 25px 0`};
  text-align: right;
  color: #31435A;
  font-family: ‘Roboto’, sans-serif;
  font-size: 24px;
  @media (max-width: 1050px) {
    font-size: 17px;
  }
  @media (max-width: 900px) {
    font-size: 12px;
  }
  @media (max-width: 425px) {
    margin: ${({bold}) => bold ? `2px 0 2px; 0` : `10px 0 10px 0`};
    text-align: center;
    width: 80%;
  }
`;

export const HeroImage = styled.div`
  align-self: ${props => props.reversed ? "flex-end" : "flex-start"};
  display: flex;
  flex-direction: column;
  margin: 0 0 0 0;
  width: 50%;
  height: auto;
  @media (max-width: 425px) {
    display: none;
  }
`;

export const HeroNav = styled(Link)`
  disply: flex; 
  width: auto;
  padding: 17px;
  height: auto;
  border: 4px solid #516c93;
  box-shadow: -10px 10px 25px #00000035;
  text-decoration: none;
  color: #516c93;
  font-family: ‘Roboto’, sans-serif;
  font-size: 27px;
  font-weight: normal;
  border-radius: 18px;
  background: transparent;
  align-self: flex-start;
  @media (max-width: 1050px) {
    font-size: 15px;
    padding: 10px;
  }
  @media (max-width: 900px) {
    font-size: 15px;
    padding: 10px;
  }
  @media (max-width: 425px) {
    font-size: 20px;
    align-self: center;
    margin-top: 20px;
    padding: 5px 10px 5px 10px;
  }
`;

export const ServicesNav = styled(Link)`
  disply: flex; 
  width: auto;
  padding: 17px;
  height: auto;
  border: 4px solid #516c93;
  box-shadow: -10px 10px 25px #00000030;
  text-decoration: none;
  color: #516c93;
  font-family: ‘Roboto’, sans-serif;
  font-size: 27px;
  font-weight: normal;
  border-radius: 18px;
  background: transparent;
  align-self: center;
  @media (max-width: 1050px) {
  }
  @media (max-width:900px) {
    padding: 10px;
    font-size: 18px;
  }
  @media (max-width: 425px) {
  }
`;

  export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 0 0;
  background-color: #516C93;
  height: 100px;
  width: 100%;
  @media (max-width: 900px) {
    height: 50px;
  }
`;

export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  height: auto;
  margin: 0 0 0 50px;
  background-image: url(${Logo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 425px) {
    margin: 0 0 0 20px;
    width: 30%;
  }
`;

export const HeaderNav = styled.div`
  display: flex; 
  flex-direction: row; 
  height: 100px;
  width: 35%;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 900px) {
    height: 50px;
  }
  @media (max-width: 425px) {
    width: 60%;
  }
`;

export const SloganBold = styled(({ reversed, ...props }) => (<h1 {...props} />))`
  color: ${({reversed}) => reversed ? `#ffff` : `#31435A`};
  text-align: ${({reversed}) => reversed ? `left` : `right`};
  display: block;
  font-size: 60px;
  font-family: ‘Roboto’, sans-serif;
  font-weight: bold;
  width: 100%;
  height: auto;
  margin: 5px 0 5px 0;
  @media (max-width: 1050px) {
    font-size: 45px;
  }
  @media (max-width: 900px) {
    font-size: 30px;
  }
  @media (max-width: 425px) {
    font-size: 25px;
    text-align: center;
  }
`;

export const SloganNormal = styled(({ reversed, ...props }) => (<h1 {...props} />))`
  color: ${({reversed}) => reversed ? `#ffff` : `#31435A`};
  text-align: ${({reversed}) => reversed ? `left` : `right`};
  display: block;
  font-size: 60px;
  font-family: ‘Roboto’, sans-serif;
  width: 100%;
  height: auto;
  font-weight: 400;
  margin: 0 0 0 0;
  @media (max-width: 1050px) {
    font-size: 45px;
  }
  @media (max-width: 900px) {
    font-size: 40px;
  }
  @media (max-width: 425px) {
    font-size: 25px;
    text-align: center;
  }
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HeroSlogan = styled.h1`
  display: block;
  text-align: left;
  font-size: 60px; 
  color: #31435A;
  font-family: ‘Roboto’, sans-serif;
  font-weight: bold;
  width: 100%;
  height: auto;
  margin: 5px 0 5px 0;
  @media (max-width: 1050px) {
    font-size: 35px;
  }
  @media (max-width: 900px) {
    font-size: 25px;
  }
  @media (max-width: 425px) {
    text-align: center;
    margin: 0 0 0 0;
    font-size: 25px;
  }
`;

export const ImgBackground = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 850px;
  background-image: url(${Map});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const ContentSectionBlue = styled(({ reversed, ...props }) => (<div {...props} />))`
  background-image: ${({reversed}) => reversed ? `url(${BlueBackgroundB})` : `url(${BlueBackgroundA})`};
  background-position: ${({reversed}) => reversed ? `right` : `left`};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #516C93;
  margin: 0 0 0 0;
  width: 100%;
  height: 550px;
  @media (max-width: 1050px) {
    height: 430px;
  }
  @media (max-width: 900px) {
    height: 280px;
  }
  @media (max-width: 425px) {
    flex-direction: column;
    background-size: cover;
    justify-content: space-evenly;
    height: 300px;
  }
`;

export const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 900px;
  background-image: url(${Map});
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: 1050px) {
    background-size: 1250px;
    height: 700px;
  }
  @media (max-width:900px) {
  }
  @media (max-width: 425px) {
    height: 1000px;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-content: center;
  width: 80%;
  height: 550px;
  background-color: #EFEFEF;
  box-shadow: 40px 40px 80px #00000029;
  border-radius: 18px;
  padding: 50px;
  @media (max-width: 1050px) {
    padding: 25px;
    height: 500px;
  }
  @media (max-width:900px) {
  }
  @media (max-width: 425px) {
    width: 70%;
    align-items: center;
    height: 800px;
  }
`;

export const InfoHeader = styled(({ contact, ...props }) => (<div {...props} />))`
  margin: ${({contact}) => contact ? `40px 0 80px 0;` : `80px 0 80px 0;`};
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-self: center;
  justify-content: center;
  @media (max-width: 1050px) {
    margin: ${({contact}) => contact ? `20px 0 40px 0;` : `80px 0 80px 0;`};
  }
  @media (max-width:900px) {
  }
  @media (max-width: 425px) {
    margin: ${({contact}) => contact ? `25px 0 55px 0` : `65px 0 45px 0`};
  }
`;


export const InfoTextHeader = styled(({ contact, ...props }) => (<h1 {...props} />))`
  display: flex;
  color: #31435A;
  font-size: 60px;
  font-family: ‘Roboto’, sans-serif;
  font-weight: bold;
  text-align: center;
  @media (max-width: 1050px) {
    font-size: 40px;
  }
  @media (max-width:900px) {
  }
  @media (max-width: 425px) {
    font-size: 35px;
    margin: 0 0 0 0;
  }
`;

export const InfoBody = styled(({ services, contact, ...props }) => (<div {...props} />))`
  justify-content: ${({services}) => services ? `center` : `space-around`};
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  @media (max-width: 1050px) {
  }
  @media (max-width:900px) {
    flex-direction: ${({contact}) => contact ? `row` : `column`};
    align-items: center;
    justify-content: space-evenly;
  }
  @media (max-width: 425px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  @media (max-width: 1050px) {
    width: 15%;
  }
  @media (max-width:900px) {
  }
  @media (max-width: 425px) {
    height: 50%;
  }
`;

export const ContactLogo = styled(({ email, ...props }) => (<div {...props} />))`
  background-image: ${({email}) => email ? `url(${IconAt})` : `url(${IconPhone})`};
  display: flex;
  width: 100%;
  height: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 1050px) {
    height: 55px;
  }
  @media (max-width:900px) {
  }
  @media (max-width: 425px) {
  }
`;

export const InfoTextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: auto;
  margin: 80px 0 50px 0;
  @media (max-width: 425px) {
    margin: 20px 0 20px 0;
    width: auto;
    align-self: center;
  }
`;

export const InfoText = styled(({ light, contact, ...props }) => (<h1 {...props} />))`
  font-weight: ${({light}) => light ? `normal` : `bold`};
  opacity: ${({light}) => light ? `0.5` : `1`};
  font-size: 20px;
  color: #31435A;
  justify-content: center;
  margin: 2px 0 2px 0;
  text-align: center;
  font-family: ‘Roboto’, sans-serif;
  @media (max-width: 1050px) {
    font-size: 15px;
  }
  @media (max-width:900px) {
  }
  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

export const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  @media (max-width: 425px) {
    width: 100%;
    margin: 35px 0 15px 0;
  }
`;

export const MessageFormName = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const MessageFormBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
`;

export const TextInputSmall = styled.input`
  width: 100%;
  box-sizing: border-box;
  outline: 0;
  border: 4px solid #516C93;
  background: transparent;
  border-radius: 18px;
  font-size: 18px;
  margin-bottom: 10px;
  padding: 2px 10px;

  
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #31435A;
    opacity: 50%;
  }
  :-ms-input-placeholder {
    opacity: 50%;
    color: #31435A;
  }
`;

export const TextInputLarge = styled.input`
  height: 80px;
  width: 97.5%;
  border: 4px solid #516C93;
  background: transparent;
  border-radius: 18px;
  margin-bottom: 20px;
  font-size: 18px;
  font-family: ‘Roboto’, sans-serif;
  
  

  ::placeholder,
  ::-webkit-input-placeholder {
    opacity: 50%;
    color: #31435A;
  }
  :-ms-input-placeholder {
    opacity: 50%;
    color: #31435A;
  }
`;

export const ButtonSubmit = styled.button`
  height: 35px;
  width: 100%;
  border: 4px solid #516C93;
  background: transparent;
  border-radius: 18px;
  color: #31435A;
  font-family: ‘Roboto’, sans-serif;
  font-size: 18px;
`;


export const SloganBox = styled.div`
  margin: 0 0 0 80px;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 50%;
  @media (max-width: 1050px) {
    margin: 0 0 0 60px;
  }
  @media (max-width:900px) {
    margin: 0 0 0 40px;
  }
  @media (max-width: 425px) {
    margin: 0;
    width: 100%
  }
`;

export const SloganBoxReversed = styled.div`
  margin: 0 80px 0 0;
  display: flex;
  flex-direction: column;
  align-self: center; 
  width: 50%;
  @media (max-width: 900px) {
    margin: 0 40px 0 0;
  }
  @media (max-width: 425px) {
    margin: 0;
    width: 100%;
  }
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`; 

export const ServicesInfo = styled(({ shadowed, ...props }) => (<h1 {...props} />))`
  background-color: ${({shadowed}) => shadowed ? `#EFEFEF` : `#ffff`};
  border-radius: ${({shadowed}) => shadowed ? `25px` : `0`};
  box-shadow: ${({shadowed}) => shadowed ? `40px 40px 80px #0000001A` : `0`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20%;
  height: 475px;
  margin: 0 50px 0 50px;
  padding: 0 10px 50px 10px;
  @media (max-width: 1050px) {
  }
  @media (max-width:900px) {
    flex-direction: row;
    align-content: space-around;
    width: 70%;
    height: auto;
    margin: 0 0 115px 0;
    padding: 50px 50px 50px 50px;
  }
  @media (max-width: 425px) {
    flex-direction: column;
    width: 90%;
    height: auto;
    margin: 0 0 115px 0;
    padding: 50px 0 50px 0;
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 125px;
  padding: 20px 0 30px 0;
  @media (max-width: 900px) {
    height: auto;
    width: auto;
    padding: 0;
    margin: 0 0 0 0;
  }
  @media (max-width: 425px) {
    height: auto;
    padding: 0;
    margin: 0 0 40px 0;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 0 0;
  background-color: #516C93;
  padding: 0 0 50px 0;
  height: 450px;
  width: 100%;
  @media (max-width: 1050px) {
    height: auto;
  }
  @media (max-width: 900px) {
    height: 100%;
  }
  @media (max-width: 425px) {
    height: auto;
  }
`;

export const Content = styled.div`

`;

export const FooterContentBox = styled(({ spaced, reversed, ...props }) => (<div {...props} />))`
  width: ${({reversed}) => reversed ? `20%` : `40%`};
  margin: ${({reversed}) => reversed ? `75px 75px 0 0` : `75px 0 0 75px`};
  justify-content: ${({reversed}) => reversed ? `flex-end` : `flex-start`};
  display: flex;
  flex-direction: row;
  height: auto;
  @media (max-width: 900px) {
    margin: ${({reversed}) => reversed ? `35px 35px 0 0` : `35px 0 0 35px`};
  }
  @media (max-width: 425px) {
    width: ${({reversed}) => reversed ? `40%` : `auto`};
    justify-content: space-evenly;
    margin: ${({reversed}) => reversed ? `35px 15px 0 0` : `35px 0 0 15px`};
  }
`;

export const FooterContent = styled(({ spaced, ...props }) => (<div {...props} />))`
  width: ${({spaced}) => spaced ? `100%` : `70%`};
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0 0 0 0;
  @media (max-width: 425px) {
    
  }
`;

export const FooterSlogan = styled(({ reversed, ...props }) => (<h2 {...props} />))`
  text-align: ${({reversed}) => reversed ? `right` : `left`};
  display: block;
  font-size: 32px;
  font-family: ‘Roboto’, sans-serif;
  font-weight: bold;
  color: #ffff;
  margin: 0 0 50px 0;
  width: 100%;
  height: auto;
  @media (max-width: 1050px) {
    font-size: 25px;
  }
  @media (max-width: 900px) {
    font-size: 12px;
  }
  @media (max-width: 425px) {
    font-size: 11px;
    margin: ${({reversed}) => reversed ? `0 0 25px 0;` : `0 0 50px 0;`};
  }
`;

export const  FooterText = styled.h3`
  font-size: 20px;
  font-family: ‘Roboto’, sans-serif;
  font-weight: normal;
  color: #ffff;
  margin: 0 0 10px 0;
  @media (max-width: 1050px) {
    font-size: 15px;
  }
  @media (max-width: 900px) {
    font-size: 8px;
  }
`;